import React, { useRef, useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import { Section, ShowcaseSlider } from "../components/elements";
import Layout from "../components/layouts/Layout";
import { darken } from "polished";
import { Color, Media, Wrapper } from "../components/utilities";

const ShowcaseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  ${Media.below.desktop`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`;

const ShowcaseImage = styled.div`
  height: 400px;
  background-color: ${darken(0.2, Color.white)};
  background-size: cover;
  background-position: center center;
  transition: all 0.5s ease;

  ${(props) =>
    props.image &&
    `
    background-image: url(${props.image});
  `}
`;

const ShowcaseItem = styled.a`
  cursor: pointer;
  transition: all 0.3s ease;
  overflow: hidden;

  &:hover {
    ${ShowcaseImage} {
      transform: scale(1.1);
    }
  }
`;

const ShowcasePage = ({ data }) => {
  const [change, setChange] = useState({
    count: 0,
    slide: null,
  });
  const node = data.allWpPage.edges[0].node;
  const sectionRef = useRef(null);

  return (
    <Layout
      internal={true}
      title={node.pageFields.seoTitle || node.title}
      description={node.pageFields.seoDescription}
    >
      <Section wrapperMaxWidth="900px">
        <h3>{node.title}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: node.content,
          }}
        ></div>
      </Section>
      <Wrapper ref={sectionRef}>
        <ShowcaseSlider
          slides={data.allWpShowcaseBlock.edges}
          change={change}
        />
      </Wrapper>
      <Section>
        <ShowcaseGrid>
          {data.allWpShowcaseBlock.edges.map((edge, ix) => (
            <ShowcaseItem
              key={ix}
              onClick={() => {
                setChange({
                  count: change.count + 1,
                  slide: ix,
                });
                window.scrollTo(0, sectionRef.current.offsetTop - 30);
              }}
            >
              <>
                <ShowcaseImage
                  title={edge.node.title}
                  image={
                    edge.node.featuredImage
                      ? edge.node.featuredImage.node.localFile.childImageSharp
                          .gatsbyImageData.images.fallback.src
                      : null
                  }
                />
              </>
            </ShowcaseItem>
          ))}
        </ShowcaseGrid>
      </Section>
    </Layout>
  );
};

export default ShowcasePage;

export const ShowcasePageQuery = graphql`
  query ShowcasePageQuery($id: String!, $slug: String!) {
    allWpPage(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          content
          pageFields {
            seoTitle
            seoDescription
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
    allWpConfig {
      edges {
        node {
          title
        }
      }
    }
    allWpShowcaseBlock(
      filter: { showcaseBlocks: { pageSlug: { eq: $slug } } }
      sort: { fields: showcaseBlocks___order, order: ASC }
    ) {
      edges {
        node {
          title
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 400, layout: FIXED)
                }
              }
            }
          }
          showcaseBlocks {
            mediaVideo
            mediaImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
